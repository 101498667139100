import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    main:{
        height:'100%',  
        padding:'50px 100px 20px 100px',
        background:'rgba(50,50,50)',
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
    },
    line1:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
    },
    column:{
        margin:'0vw 10vw 0vw 10vw',
        //border:'2px solid white',
    },
    title:{
        marginBottom:'30px',
        fontWeight:'bold',
        fontSize:'1.1em',
        textAlign:'center',
        color:'white',
    },
    text:{
        marginBottom:'10px',
        color:'white',
        textAlign:'center',
        cursor:'pointer',
    },
    line2:{
        marginTop:'50px',
        color:'white',
    }


})


function Component(){
    const classes = useStyles()
    
    return(
        <div className={classes.main}>

            <div className={classes.line1}>
                <div className={classes.column}>
                    <div className={classes.title}>
                        Articles les plus consultés
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/articleSap'}}>
                        Services à la Personne
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/articleAvanceImmediate'}}>
                        Avance Immédiate
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/articleOutil'}}>
                        Logiciels de gestion
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/articleBanque'}}>
                        Banques
                    </div>
                </div>

                <div className={classes.column}>
                    <div className={classes.title}>
                        Liens utiles
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/mentionLegale'}}>
                        Mentions légales
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/contact'}}>
                        Nous contacter
                    </div>
                    <div className={classes.text} onClick={()=>{window.location.href='/contact'}}>
                        Devenir partenaire
                    </div>
                </div>
            </div>


            <div className={classes.line2}>
                Tous droits réservés. Servicealapersonne.fr
            </div>
        </div>
    )
}

export default Component