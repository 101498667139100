import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import clsx from 'clsx'

import Style from './component'
import cookie from './cookieComponent'
import mongoWrite from './mongoWrite'
import Logo from './logoPink.png'

const useStyles = makeStyles({
    main:{
        padding:'10px 0vw 0px 5vw',
        height:'40px',

        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'end',

        //border:'2px solid',
        borderBottom:'1px solid '+Style.color.main2,
        "@media (max-width: 1100px)": {
            height:'100%',
            paddingLeft:'5vw',//pour centrer
            flexDirection:'column',
            alignItems:'center',
            //justifyContent:'center',
        },
    },
    logo:{
        height:'50px',
        //width:'50px',
        //border:'2px solid',
        textDecoration:'none',
        cursor:'pointer',
        "@media (max-width: 700px)": {
            height:'30px',
        },
    },
    menu:{
        marginTop:'20px',
        width:'60vw',
        maxWidth:'800px',
        
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'end',

        //border:'2px solid',
        "@media (max-width: 1100px)": {
            flexDirection:'column',
            justifyContent:'center',
        },
    },
    title:{
        width:'100%', //permet de prendre toute la place dans menu, et d'augmenter la zone cliquable
        height:'30px',

        fontSize:'1.1em',
        fontWeight:'bold',
        color:Style.color.main1,
        textAlign:'center',
        cursor:'pointer',
        //border:'2px solid',
        borderBottom:'6px solid transparent', //permet de ne pas bouger le texte quand on ajoute le hoover borderBottom
        textDecoration:'none',

        transition:'0.5s ease-out',
        "&:hover": {
            fontWeight:'bold',
            borderBottom:'6px solid '+Style.color.main1,
            transition:'0.2s ease-out',
        },
    },
    menuCross:{
        display:'none',
        "@media (max-width: 1100px)": {
            display:'inline',
        },
    },
})



function Component(props){
    const classes = useStyles()
    const [menuShow, setMenuShow] = useState(true)
    
    
    //menu
    const [menu, setMenu] = useState('guide')
    console.log(window.location.href)
    useEffect(()=>{
        //style
        let list = ['guide', 'barometre', 'annuaire' , 'contact']
        for(let component of list){if((window.location.href).toLowerCase().includes(component)){
            document.getElementById(component).style.borderBottom='6px solid '+Style.color.main2
        }}
        //décide si le menu est ouvert ou pas au debut
        if(window.innerWidth<1100){setMenuShow(false)}
    },[])
    
    //gestion des fonctions sites
    window.onload=() => {
        cookie()
        mongoWrite({request:'write', collection:'visite', body:{}}) //permet de ne le lancer qu'une seule fois !
    }
    
    return(
        <div className={classes.main}>
            
            <img src={Logo} alt='logo' className={classes.logo} onClick={()=>{window.location='/'}}/>
            
            {!menuShow && 
                <div className={classes.menu}>
                    <div className={classes.title} onClick={()=>{setMenuShow(true)}}>
                        Menu
                    </div>
                </div>
            }
            
            {menuShow && 
                <div className={classes.menu}>
                    <a className={classes.title} id='guide' href='/guide'>
                        Guide
                    </a>
                    <a className={classes.title} id='barometre' style={{color:'rgba(200,200,200)'}} onClick={()=>{props.changePopup(); mongoWrite({request:'write', collection:'barometre', body:{source:'header'}})}}>
                        Baromètre
                    </a>
                    <a className={classes.title} id='annuaire' style={{color:'rgba(200,200,200)'}} onClick={()=>{props.changePopup(); mongoWrite({request:'write', collection:'annuaire', body:{source:'header'}})}}>
                        Annuaire
                    </a>
                    <a className={classes.title} id='partenaire' href='/partenaires'>
                        Partenaires
                    </a>
                    <a className={classes.title} id='contact' href='/contact'>
                        Contact
                    </a>
                    <a className={clsx(classes.title,classes.menuCross)} onClick={()=>{setMenuShow(false)}}>X</a>

                </div>
            }
            
        </div>
    )
}

export default Component