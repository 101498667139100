import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import intro from './confident2.jpg'
import Tableau from './tableau'

function Element(){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Comparatifs Assurances Professionnelles - Guide SAP professionnels//#endregion</title>
      <meta name="description" content="Comparatif des assurances professionnelles : Comment bien choisir son assurance, à quelles obligations sont soumis les professionnels ?"/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Les Assurances professionnelles
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 15 mai 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, width:'100%',}}>
        Sommaire
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span><a href='#rcpro'>1. La Responsabilité Civile Professionnelle obligatoire ?</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#choix'>2. Quel critère pour choisir son assureur ?</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#comparateur'>3. Notre comparateur</a>
      </div>
      
      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Le choix de la souscription à une assurance professionnelle est une question cruciale.
        <br/> Nous allons tout de suite mettre fin au suspens : La souscription d'une assurance professionnelle pour une activité de Service à la Personne n'est pas obligatoire, mais fortement recommandée.
        <br/>
        <br/>Dans cet article nous verrons ensemble les différents types d'assurances qui protègent votre activité, leurs couvertures et enfin un comparatif des assurances aujourd'hui sur le marché.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='rcpro'>
        Responsabilité Civile Professionnelle
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La Responsabilité Civile Profesionnelle (aussi connu sous le doux nom RC Pro), couvre votre organisme contre les préjudices causés à autrui, qu'il s'agisse d'un client, d'un fournisseur ou encore d'un salarié.
        <br/>
        <br/>Cette assurance couvre l'ensemble des accidents qui pourrait advenir lors de la réalisation de votre activité :
        <ul>
          <li>Dégâts corporels ;</li>
          <li>Détérioration matérielle ;</li>
          <li>Ou pertes financières.</li>
        </ul>
        Dans le contexte du Service à la Personne, les risques rencontrés peuvent être nombreux : manipulation d'une personne fragile, dégradation accidentelle du logement du particulier ou de son mobilier par vous ou un de vos salariés, déplacement jusqu'aux domiciles de vos clients, ...
        <br/>
        <br/>Comme vu plus haut, celle-ci n'est pas obligatoire dans le secteur du SAP, contrairement à d'autres types d'activités (médical, bâtiment, conseils, ...).
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='choix'>
        Comment choisir le bon assureur ?
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Couverture complémentaires</span>
        <br/><span style={{color:'white'}}> .......... </span>
        En fonction de votre activité, il peut être interessant de définir les couvertures dont vous aurez le plus besoin, d'autant plus si votre structure compte un ou plusieurs salariés. 
        <br/>Par exemple les prestations de "petits bricolages" n'auront pas les mêmes implications que celles d'aides de vie en contact avec des personnes fragiles.
        <br/>
        <br/>On compte principalement les couvertures complémentaires suivantes :
        <ul>
          <li><strong>RC d'exploitation : </strong>Couvre les incidents autour de la réalisation de votre activité (un client se blesse en pénétrant dans votre bureau).</li>
          <li><strong>RC après livraison : </strong>Couvre les dégâts causés par la prestation après sa réalisation (petit bricolage, jardinage, ...).</li>
          <li><strong>Pertes d'exploitation : </strong>Compense la baisse de chiffre d'affaires en cas de sinistre subi par l'entreprise.</li>
          <li><strong>Protection juridique : </strong> Afin d'être représenté par un professionnel en cas de litige devant un tribunal.</li>
        </ul>
        
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Prix mensuel</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Celui-ci dépend grandement de votre activité, du nombre de salariés, ... 
        <br/>Les prix indiqués dans le comparatif ci-dessous sont données à seul titre indicatif.
        <br/> 
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Expérience utilisateur</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Certains assureurs pourront être rencontrés dans leurs différentes agences sur le territoire, et/ou proposer une plateforme intuitive où souscrire et retrouver l'ensemble de vos documents.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='comparateur'>
        Comparatif des assurances de Responsabilité Civile Pro
      </div>
      <Tableau/>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <br/><span style={{color:'white'}}> .......... </span>
        Comme nous l'avons vu, la souscription d'une assurance de Responsabilité Civile Professionnelle n'est pas obligatoire mais vivement conseillée, afin de protéger votre activité sur le long terme, d'autant plus si votre activité présente des risques ou si vous comptez des salariés.
        <br/>
        <br/>A travers nos différents critères et couvertures complémentaires proposées, vous avez maintenant les outils pour sélectionner l'assureur qui correspond le mieux à votre activité.
        <br/>N'hésitez pas à réaliser plusieurs devis avant de fixer votre choix définitif.
        <br/>
        <br/>En espérant que cet article ai pu vous aider à voir plus clair dans les nombreuses offres d'assurance de Responsabilité Civile Professionnelle !
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
    </div>
    </div>
    </>)
}

export default Element