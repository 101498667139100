import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import intro from './anxious.jpg'
import calendrier from './calendrier7.png'
import nova from './novaTrimestre.png'

function Element(a, e){
  const classes = ComponentArticle.useStyles()
 
  return(<>
    <Helmet>
      <title>Les déclarations - Guide SAP professionnels</title>
      <meta name="description" content="Les professionnels du Service à la Personne doivent réaliser certaines déclarations, car ils opèrent dans un cadre légal spécifique."/>
      </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Professionnel du SAP : Les Déclarations
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 1 mars 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'60vw', maxWidth:'500px', marginTop:'30px', marginBottom:'-50px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        En plus des déclarations que doivent réaliser toute société, les professionnels du Service à la Personne opèrent dans un cadre légal spécifique.
        <br/>Nous avons donc souhaité créer un article rassemblant l'ensemble des démarches administratives à effectuer lors de votre activité SAP.
        <br/>
        <br/>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Au Menu aujourd'hui :</span>
        <br/><span style={{color:'white'}}> .......... </span><a href='#facturation'>1. Facturation</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#attestation'>2. Attestations fiscales de fin d'année</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#nova'>3. Déclarations Nova</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#chiffreAffaire'>4. Déclarations du chiffre d'affaires (auto-entrepreneurs)</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#resultat'>5. Déclarations du résultat (société)</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#tva'>6. TVA (seulement si assujetti)</a>
      </div>


      <img className={classes.image} src={calendrier} style={{width:'80vw', maxWidth:'800px', marginTop:'30px', marginBottom:'-50px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='facturation'>
        1. Facturation
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Pour les prestations de services réalisées auprès de particuliers, la réalisation d'une facture est obligatoire, selon l'<a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033747505' target='_blank' rel='nofollow'>article D7233-1 du code du travail</a>.
        Pour être conforme, vos factures doivent indiquer :
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Identité des acteurs : </span>
        <ul>
          <li>La date et le numéro de facture ;</li>
          <li>La raison sociale, l'adresse, le SIRET, forme juridique et capital social de la structure ;</li>
          <li>Le nom et l'adresse du client ;</li>
        </ul>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Prestations réalisées et prix: </span>
        <ul>
          <li>La nature, la quantité et le prix de chaque prestation ;</li>
          <li>Réduction opérée sur les prix ;</li>
          <li>Montant total HT et TTC.</li>
        </ul>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Conditions de vente : </span>
        <ul>
          <li>Le délai de paiement et pénalités exigibles en cas de retard ;</li>
          <li>Les conditions d'escomptes en cas de paiement anticipé (par exemple "Pas d'escompte en cas de paiement anticipé");</li>
          <li>Optionnel : Les moyens de règlements acceptés et coordonnées bancaires ;</li>
          <li>Si vous êtes non assujetti à la TVA : La mention "TVA non applicable, art. 293 B du Code général des impôts" ;</li>
          <li>Si vous y êtes assujetti : Votre numéro d'identification à la TVA ;</li>
        </ul>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Informations liées au SAP : </span>
        <ul>
          <li>Le logo du Service à la Personne ;</li>
          <li>Les numéros de vos déclarations et agréments, ainsi que leur date d'obtention ;</li>
          <li>L'acompte, les frais de déplacement, et le numéro de l'intervenant.</li>
        </ul>
        <br/>Votre logiciel de facturation dédié au Service à la Personne comprend déjà l'ensemble de ces éléments. 
        <br/>Pour être conforme et vous faire gagner du temps, nous avons créé un partenariat avec la solution de gestion dédiée au SAP Avance Immédiate Services :
      </div>
      <a className={Component.style().button} href='/https://avance-immediate.fr/redirection/guidesap' style={{margin:'10px 0px 20px 0px'}}>
        Découvrir Avance Immédiate Services
      </a>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='attestation'>
        2. Attestations fiscales
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Particularité du Service à la Personne : Afin de pouvoir exercer leurs 50% de crédit d'impôt, les particuliers doivent détenir une attestation fiscale annuelle.
        <br/>Celle-ci doit être fournie par le professionnel avant le 31 mars de l'année suivant les prestations.
        <br/>
        <br/>Suivant l'<a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033747494' target='_blank' rel='nofollow'>article D7233-4</a> du code du travail, une attestation fiscale doit indiquer les informations suivantes :
        <ul>
          <li>Le nom, l'adresse et le SIRET du professionnel ;</li>
          <li>Le numéro et la date d'obtention de son numéro SAP ;</li>
          <li>Le nom et l'adresse du particulier ;</li>
          <li>La synthèse des prestations réalisées : leur date, quantité, montant acquitté et le code de l'intervenant.</li>
        </ul>
        Comme pour votre facturation, les attestations fiscales peuvent être générées automatiquement par votre logiciel dédié au SAP. 
      </div>

      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='nova'>
        3. Déclarations Nova
      </div>
      <img className={classes.image} src={nova} style={{width:'80vw', maxWidth:'800px', marginTop:'0px', marginBottom:'0px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription}style={{width:'80vw', maxWidth:'800px', marginBottom:'20px'}}>Nova : Déclaration trimestrielle d'activité</div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Seconde particularité de notre secteur, les professionnels ont le devoir de déclarer sur Nova les prestations réalisées chaque fin de trimestre, ainsi qu'un bilan annuel.
        Ces informations seront utilisées à des fins statistiques afin d'analyser le pouls économique de notre secteur d'activité. 
        <br/>Les déclarations trimestrielles doivent être réalisées :
        <ul>
          <li>Premier trimestre : jusqu'au 15 mai ;</li>
          <li>Second trimestre : jusqu'au 15 août ;</li>
          <li>Troisième trimestre : jusqu'au 15 novembre ;</li>
          <li>Quatrième trimestre : jusqu'au 15 février de l'année suivante.</li>
        </ul>
        La déclaration annuelle "TSA/Bilan" demande de plus amples détails sur vos clients (aides APA, PCH) et vos salariés (ages, sexes, niveau de formation).
        Elle doit être réalisée avant le 30 juin de chaque année.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='chiffreAffaire'>
        4. Déclaration du chiffre d'affaires (auto-entrepreneur)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les auto-entrepreneurs doivent déclarer leur chiffre d'affaires auprès de l'Urssaf, mensuellement ou trimestriellement en fonction de l'option sélectionnée.
        <br/>
        <br/>Cette déclaration est à réaliser au seni de leur espace Urssaf, onglet Déclarer et payer / Mes échéances en cours.
        <br/>Vous pourrez alors renseigner vos recettes dans la case liée à votre type d'activité. 
      </div>





      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='resultat'>
        5. Déclarations du résultat (société)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les sociétés doivent déclarer leur résultat et impôt sur les sociétés sur le site <a href='https://www.impots.gouv.fr/accueil' target='_blank' rel='nofollow'>impots.gouv.fr</a>.
        <br/>
        <br/>Les structures qui cloturent leurs comptes le 31 décembre, doivent réaliser leurs déclarations avant le 2ème jour ouvré de mai.
        <br/>Pour les autres, elles devront les réaliser 3 mois après leur cloture comptable.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='tva'>
        6. Taxe sur la valeur ajoutée
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Il existe 3 régimes de TVA :
        <ul>
          <li>Régime de franchise de base : Aucune déclaration à réaliser (la structure est exonérée de TVA).</li>
          <li>Régime Simplifié : Une déclaration annuelle et 2 acomptes.</li>
          <li>Régime Réel Normal : Déclaration et règlement mensuel.</li>
        </ul>
        
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Régime de franchise de base </span>
        <br/>Seules les structures de SAP avec un chiffre d'affaires inférieur à 36 800€/an peuvent demander ce régime.
        <br/>
        <br/>Elles conservent donc la tva perçues sur leurs ventes, mais ne peuvent demander remboursement de la TVA sur leurs achats.
        <br/>Ce régime est d'autant plus intéressant dans notre secteur de services, avec peu de charges (hors salaires mais eux sans TVA).
        <br/>
        <br/>

        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Régime Simplifié </span>
        <br/>Afin d'être rattaché à ce régime, la structure doit avoir un chiffre d'affaires inférieur à 254 000€ ET ne doit pas dépasser un montant de TVA de 15 000€.
        <br/>
        <br/>Elle aura alors une seule déclaration à réaliser :
        <br/>Si son exercice cloture le 31/12 : La déclaration sera à réaliser avant le 2ème jour ouvré de mai ;
        <br/>Sinon elle aura 3 mois après sa cloture comptable pour réaliser sa déclaration.
        <br/>
        <br/>De plus deux acomptes devront être réglés, basés sur la précédente déclaration de TVA :
        <br/>En juillet à hauteur de 55% de la précédente déclaration ;
        <br/>En Décembre à hauteur de 40%.
        <br/>
        <br/>

        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Régime Réel Normal </span>
        <br/>Dans tous les autres cas c'est ce régime qui sera attribué à votre structure.
        <br/>Les déclarations et règlements sont ici à réaliser chaque mois, avant un jour défini en fonction de votre département selon <a href='https://www.impots.gouv.fr/le-regime-reel-normal-dates-limites-de-transmission-des-ca3' target='_blank' rel='nofollow'>la documentation du site des impots</a>.
        <br/>
        <br/>
        En cas de changement de régime par dépassement des seuils par exemple, ce sera au professionnel de demander le changement de régime via la Messagerie sécurisée de son espace impots.gouv.fr
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Vous n'avez maintenant plus aucune excuse pour rater une échéance !
        <br/>Vous pouvez désormais développer sereinement votre activité, sans la peur d'une mystérieuse déclaration qui viendrait toquer à votre porte un 31 décembre (oui ça sent le vécu...).
        <br/>
        <br/>Retrouver notre <a href='/articleImpot'>article dédié</a> à l'imposition de votre structure : A quels impôts allez-vous devoir vous conformer et comment sont calculés leur montant ?
      </div>
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element