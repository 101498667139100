import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import intro from './people.jpg'
import interlude from './draw2.jpg'
import interlude2 from './draw.jpg'
import conclusion from './people2.jpg'

function Element(){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Recruter dans le Service à le Personne</title>
      <meta name="description" content="Retrouver notre guide complet dédié au Service à la Personne. Comment recruter et trouver des intervenants et salariés pour votre activité ?"/>
      </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Recruter dans le SAP
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 2 mars 2024
      </div>
      


      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'900px', marginTop:'30px', marginBottom:'-30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Vous souhaitez ajouter un talent à votre équipe ou trouver un nouvel intervenant ? 
        <br/>Le nombre d'offres d'emplois a aujourd'hui dépassé la demande pour le secteur du Service à la Personne.
        <br/>
        <br/>Nous allons voir aujourd'hui plusieurs moyens d'augmenter vos chances de trouver le talent qui pourra vous accompagner dans la croissance de votre activité !
        <br/>
        <br/>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Les différents canaux d'acquisition :</span>
          <br/><span style={{color:'white'}}> .......... </span><a href='#fidelisation'>1. Les écoles spécialisées en Alternance</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#prix'>2. Les sites d'emplois dédiés aux SAP</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#prix'>3. Les réseaux sociaux professionnels</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#qualite'>4. Les sites généralistes</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#qualite'>5. Les chasseurs de têtes</a>
        </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        1. Les écoles spécialisées en Alternance
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Depuis quelques années, la formation en alternance connait un réel engouement. 
        <br/>Et pour cause, elle permet à l'étudiant une rémunération attractive et l'employeur profite de nombreuses subventions.
        Effectivement l'aide à l'embauche permet une prise en charge de 6 000€ sur la première année d'alternance. De plus votre Opco prend en charge la quasi-totalité des frais de scolarité de votre alternant.
        <br/>
        <br/>Ces écoles sont présentes sur l'ensemble du territoire et proposent des formations variées et de tous les niveaux.
        <br/>Il sera possible d'y recruter vos commerciaux, gestionnaires, intervenants pour votre activité, ...
        <br/>
        <br/><span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Principaux avantages :</span>
        <ul>
          <li>Candidat de qualité ;</li>
          <li>Aide à l'embauche pour les alternants.</li>
        </ul>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Inconvénient</span>
        <ul>
          <li>Démarche à réaliser pour chaque école.</li>
          <li>L'alternance peut ne pas convenir à vos besoins (par exemple si la personne doit être présente constamment).</li>
          <li>Peu de candidat.</li>
        </ul>
      </div>


      <img className={classes.image} src={interlude2} style={{width:'50vw', maxWidth:'400px', marginTop:'0px', marginBottom:'-30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        2. Les sites d'emplois dédiés au SAP
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Il existe de nombreuses plateformes pour trouver un intervenant pour muscler votre équipe (ce canal est moins adapté pour la recherche de gestionnaires ou commerciaux).
        <br/>Plusieurs modes de fonctionnement existent, mais le plus répandu est la mise en relation de votre organisme directement avec des profils qui ont été qualifiés au préalable, vous permettant un gain de temps considérable.
        <br/>
        <br/><span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Principaux avantages :</span>
        <ul>
          <li>Candidat d'une grande qualité ;</li>
          <li>Recrutement rapide ;</li>
          <li>Des services complémentaires peuvent aussi être proposés : mise en avant de votre structure, ...</li>
        </ul>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Inconvénients</span>
        <ul>
          <li>Coût</li>
        </ul>
      </div>


      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        3. Les réseaux sociaux profesionnels
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Plus adaptés à vos besoins de recrutement coté gestion ou commercial, les réseaux sociaux professionnels vous permettent d'être mis en relation avec un grand nombre de profils. 
        <br/>
        <br/><span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Principaux avantages :</span>
        <ul>
          <li>Vous pouvez analyser en détail le profil de chaque candidat ;</li>
          <li>Nombre très important de réponses à vos offres d'emplois. </li>
        </ul>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Inconvénients :</span>
        <ul>
          <li>Nécessite un tri, car de nombreuses candidatures ne sont pas "qualifiées".</li>
          <li>Coût ou visibilité limitée</li>
        </ul>
      </div>


      <img className={classes.image} src={interlude} style={{width:'50vw', maxWidth:'400px', marginTop:'20px', marginBottom:'-30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        4. Les sites généralistes
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Certains sites généralistes permettent la mise en avant des annonces sur des thèmes variés, y compris des offres d'emplois.
        <br/>On pensera ici à Leboncoin ou aux groupes dédiés à l'emploi sur les réseaux sociaux comme Facebook.
        <br/>Ils permettent une visibilité importante mais une qualité faible. Ils peuvent toutefois être extrêmement efficaces dans votre cercle proche.
        <br/>
        <br/><span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Principaux avantages :</span>
        <ul>
          <li>Visibilité importante ;</li>
          <li>Gratuit ;</li>
          <li>Efficace dans votre cercle proche. </li>
        </ul>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Inconvénients :</span>
        <ul>
          <li>Qualité des candidatures faibles.</li>
        </ul>
      </div>






      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        5. Les chasseurs de têtes
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Plutôt tournés vers les profils issus d'écoles supérieures, les chasseurs de têtes sont redoutables pour trouver efficacement le profil idéal, tout en gérant la totalité de votre processus de recrutement.
        <br/>
        <br/>Après avoir établi ensemble vos besoins, ils réalisent une sélection de leur coté, afin de vous présenter un nombre restreint de candidats.
        Il sont souvent rémunérés uniquement lorsque le profil a terminé sa période d'essai.
        <br/>
        <br/><span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Principaux avantages :</span>
        <ul>
          <li>Gestion complète du processus de recrutement ;</li>
          <li>Capacité à dénicher et analyser les candidats ;</li>
        </ul>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Inconvénients :</span>
        <ul>
          <li>Coût élevé.</li>
        </ul>
      </div>


      <img className={classes.image} src={conclusion} style={{width:'80vw', maxWidth:'700px', marginTop:'20px', marginBottom:'-30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Il est souvent intéressant de multiplier les canaux d'acquisition, surtout lors de votre premier recrutement.
        <br/>Cela vous permettra de mieux sonder quels canaux correspondent le mieux à votre besoin.
        <br/>
      </div>
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element