import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import intro from './impot.png'
import cfe from './cfe3.png'

function Element(){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Fiscalité : La liste des impôts - Guide SAP professionnels</title>
      <meta name="description" content="Les professionnels du Service à la Personne doivent réaliser certaines déclarations, car ils agissent dans un cadre légal spécifique."/>
      </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Tout sur la fiscalité de sa structure
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 8 mars 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'800px', marginTop:'30px', marginBottom:'0px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'800px', textAlign:'end', }}>Plus plus d'informations sur l'utilisation des recettes fiscales, voir le <a href='https://www.economie.gouv.fr/enavoirpourmesimpots/mes-impots-au-quotidien' target='_blank' rel='nofollow' style={{textDecoration:'none'}}>site du gouvernement dédié</a></div>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Quand nous lançons notre activité, la fiscalité des entreprises peut sembler complexe.
        <br/>Et nous pouvons rapidement craindre de contribuer trop ou au contraire pas assez.
        <br/>
        <br/>Cet article est là afin de vous permettre de connaitre l'ensemble des contributions que doit réaliser votre entreprise ou auto-entreprise, et d'en comprendre la méthode de calcul simplement.
        <br/>
        <br/>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Sommaire :</span>
          <br/><span style={{color:'white'}}> .......... </span><a href='#chiffreAffaire'>1. Contribution sur le chiffre d'affaires (auto-entreprise)</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#tva'>2. TVA (Taxe sur la Valeur Ajouté)</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#cfe'>3. CFE (Cotisation Foncière des Entreprises)</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#cvae'>4. CVAE (Cotisation sur la Valeur Ajoutée des Entreprises)</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#is'>5. Impôt sur les Sociétés</a>
        </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='chiffreAffaire'>
        1. Contribution sur le chiffre d'affaires (auto-entreprise)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les auto-entreprises sont appréciées pour leur simplicité administrative.
        <br/>Leur fiscalité en fait partie : Les cotisations sont calculées sur le chiffre d'affaires et non sur leurs bénéfices, comme cela peut être le cas pour les sociétés.
        <br/>
        <br/>Pour le service à la personne, ce taux est calculé comme la somme des :
        <ul>
          <li>Cotisations sociales à 21,2% (BIC);</li>
          <li>La contribution à la formation professionnelle à 0,2% (prestations de services);</li>
          <li>La taxe des frais consulaires à 0,044% (si rattachée à la CCI, 0,48% si CMA).</li>
        </ul>
        Soit un taux total de 21,98%, auxquels il est possible de rajouter 1,7% afin de bénéficier du versement libératoire de l'impôt sur le revenu, pour atteindre 23,68%.
        <br/>Vous êtes automatiquement éxonéré de la taxe des frais consulaires si votre chiffre d'affaires est inférieur à 5 000€.
        <br/>
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='tva'>
        2. Taxe sur la Valeur Ajoutée (TVA)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les structures de SAP réalisant un chiffre d'affaires supérieur à 36 500€ sont obligatoirement asujetties à la TVA.
        <br/>Elles devront donc restituer la TVA perçues pour leurs prestations effectuées ("TVA collectée"), diminuée de la TVA liée à leurs achats ("TVA déductible").
        <br/>
        <br/>Le taux de TVA sur vos prestations diffère en fonction de l'activité réalisée (et peut être encore différent dans les DOM) :
        <ul>
          <li>Les prestations réalisées auprès de personnes fragiles bénéficient d'un taux à <strong>5,5%</strong> (hors esthétique et promenade d'animaux);</li>
          <li>Les cours à domicile (hors soutien scolaire) et les petits travaux de jardinage ont un taux à <strong>20%</strong>;</li>
          <li>Les autres activités voir leur taux fixé à <strong>10%</strong>.</li>
        </ul>
        Vous pourrez retrouver l'ensemble des taux sur le <a href='https://entreprendre.service-public.fr/vosdroits/F31596' target='_blank' rel='nofollow'>site officiel</a>.
        <br/>
        <br/>Attention certains achats réalisées par la société, ne permettent pas de récupérer la tva associée :
        <ul>
          <li>Achat d'un véhicule de tourisme, contrairement à l'achat d'un véhicule de société ;</li>
          <li>Transport de personnes (train, avion, taxi, ...) ;</li>
          <li>Frais de logement pendant le déplacements du dirigeant et des salariés ;</li>
        </ul>
        Le professionnel devra alors restituer sa TVA semestriellement (à travers les acomptes du régime simplifié) ou tous les mois (régime réel normal).
        <br/>Vous pourrez en savoir plus sur notre <a href='/articleDeclaration#tva'>article dédiée aux déclarations</a>.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='cfe'>
        3. Cotisation Foncière des Entreprises (CFE)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La CFE et la CVAE, sont les seuls impôts locaux, en effet la CFE est perçue directement par votre commune et groupement de commune.
        <br/>Seul les entreprises avec un chiffre d'affaires inférieur à 5 000€ sont exonérées de la CFE.
        <br/>
        <br/>Le montant de la CFE est déterminée par la commune et peut varier en fonction du chiffre d'affaires réalisé en n-2 :
      </div>
      <img className={classes.image} src={cfe} style={{width:'80vw', maxWidth:'500px', marginTop:'10px', marginBottom:'0px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='cvae'>
        4. Cotisation sur la Valeur Ajoutée des Entreprises (CVAE)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Comme vu précédemment la CVAE est elle aussi un impôt local. Elle est partagée par votre commune, groupement de commune, département et région.
        <br/>La CVAE est uniquement dûe pour les sociétés avec un <strong>chiffre d'affaires supérieur à 500 000€</strong>.
        <br/>
        <br/>Le montant de la CVAE est le produit entre la Valeur Ajouté de la société (Produits - Charges imputables) et un multiplicateur défini en fonction du chiffre d'affaire (entre 0,094% et 0,28%).
        <br/>Vous pourrez trouver de plus amples informations sur le <a href='https://entreprendre.service-public.fr/vosdroits/F23546' target='_blank' rel='nofollow'>site officiel</a>.
      </div>





      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='cvae'>
        5. Impôt sur les sociétés (IS)
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les auto-entreprises et société en régime d'impôt sur le revenu ne sont pas redevables de l'Impôt sur les sociétés.
        <br/>
        <br/>L'Impôt sur les Sociétés est calculé à partir du résultat avant impôt, qui est défini comme :
        <ul>
          <li><strong>Le montant des prestations réalisées lors de l'exercice comptable ;</strong></li>
          <li><strong>Diminué de vos charges fixes (loyer, comptabilité) et variables ;</strong></li>
          <li>Diminué de la dépréciation de vos actifs et provisions ;</li>
          <li>Augmenté de vos produits exceptionnels (vente d'un actif au dessus de sa valeur comptable) et revenus financiers ;</li>
          <li>Diminué de vos charges exceptionnelles et frais financiers (intérêt d'un prêt principalement).</li>
        </ul>
        Une fois le résultat avant impôt calculé, vous pouvez définir le montant de l'Impôt sur les Sociétés simplement :
        <ul>
          <li>Le résultat entre 0 et 25 000€, est taxé à hauteur de 15% ;</li>
          <li>Au dessus, celui-ci est taxé à hauteur de 25%.</li>
        </ul>
        Par exemple, si vous avez réalisé un résultat avant impôt de 100 000€,
        <br/>Vous devrez alors 15% * 25 000 + 25% * (100 000 - 25 000), soit 22 500€ d'Impôt sur les Sociétés.
      </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La fiscalité de votre entreprise n'a plus aucun secret pour vous.
        <br/>Nous avons réalisé de nombreux articles autour du Service à la Personne, afin de vous permettre de développer votre activité sereinement avec les meilleurs outils à votre disposition.
        <br/>
        <br/>Retrouvez notre guide complet en cliquant ci-dessous :
      </div>
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element