import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import mongoWrite from '../../Component/mongoWrite'


//import Certificat from './certificat.png'
import CertificationCreation from './certificationCreation'


const useStyles = makeStyles({
  main:{  
    marginBottom:'200px',
    height:2480/3+'px',
    width:3504/3+'px',
    //padding:'100px',

    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-between',
    //border:'2px solid black',
  },
  informationGroup:{
    width:'100%',
    marginTop:'10px',
    marginBottom:'20px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    //border:'2px solid',
    "@media (max-width: 1000px)": {
      flexDirection:'column',
    },
  },
  information:{
    marginTop:'20px',
    width:'20%',
    maxWidth:'250px',
    color:Component.color.main2,
    fontWeight:'bold',
    //border:'2px solid black',
    "@media (max-width: 1000px)": {
      width:'80%',
    },
  },
  input:{
    width:'100%',
    padding:'5px',
    borderRadius:'5px',
    //marginLeft:'10px',
  },
  message:{
    marginTop:'10px',
    marginBottom:'200px',
    color:Component.color.main2,
    textAlign:'center',
    //border:'2px solid',
  },
  line:{
    width:'100%',

    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',

  },
  column:{
    //w
  },
})


function Element(){
  const classes = ComponentArticle.useStyles()
  const certificationStyle = useStyles()

  function send(){
    let message=''//'Félicitation ! Votre certification est bien en cours de validation. Vous la recevrez sous quelques jours par mail.'
    //import
    let civilite = document.getElementById('civilite').value
    let prenom = document.getElementById('prenom').value
    let nom = document.getElementById('nom').value
    let mail = document.getElementById('mail').value
    //civilite
    if(civilite=='false'){message+='Veuillez sélectionner une civilité <br/>'}
    //nom
    if(nom.length<3){message+='Veuillez indiquer votre nom <br/>'}
    else{
      nom = nom.toUpperCase()
      nom = nom.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
      nom = nom.replace(/[^A-Za-z -]/g,'') //on supprime tout ce qui n'est pas des lettres 
      nom = nom.replace(/[ ]{2,}/g,' ') //on supprime les espaces multiple 
    }
    //prenom
    if(prenom.length<3){message+='Veuillez indiquer votre prénom <br/>'}
    else{
      prenom = prenom[0].toUpperCase()+prenom.slice(1,).toLowerCase()
      prenom = prenom.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
      prenom = prenom.replace(/[^A-Za-z -]/g,'') //on supprime tout ce qui n'est pas des lettres 
      prenom = prenom.replace(/[ ]{2,}/g,' ') //on supprime les espaces multiple 
    }
    //adresseMail
    mail = mail.replaceAll(' ','')
    mail = mail.toLowerCase()
    mail = mail.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
    let regex = new RegExp('^[a-z0-9-_.]{1,}[@][&-z0-9-_.]{1,}[.][a-z0-9]{1,}$')
    if(!regex.test(mail)){message+='Veuillez indiquer une adresse mail valide.'}
    //if everything is fine
    let collection = 'certificationFail'
    if(message==''){
      collection='certification'
      message='Félicitation ! Votre certification est bien en cours de validation. Vous la recevrez sous quelques jours par mail.'
      document.getElementById('bouton').style.background='rgba(200,200,200)'
    }
    let text=civilite+' '+prenom+' '+prenom
    mongoWrite({request:'write', collection:collection, body:{mail:mail, civilite:civilite, prenom:prenom, nom:nom, text:text}})
    document.getElementById('message').innerHTML=message
  }

  
    return(<>
    <Helmet>
      <title>Recruter dans le Services à le Personne</title>
      <meta name="description" content="Retrouver notre guide complet dédié au Services à la Personne. Comment recruter et trouver des intervenants et salariés pour votre activité ?"/>
      </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Félicitation !
      </h1>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:'15px', fontSize:'1.2em'}}>
        Après avoir suivi l'ensemble de nos cours, vous pourrez renseigner les informations et télécharger votre certification !
      </div>


      <div className={certificationStyle.informationGroup}>
        <div className={certificationStyle.information}>
          Civilité : 
          <br/>
          <select className={certificationStyle.input} id='civilite'>
            <option value='false' default>Sélectionner</option>
            <option value='M.'>Monsieur</option>
            <option value='Mme.'>Madame</option>
          </select>
        </div>
        <div className={certificationStyle.information}>
          Nom : 
          <br/>
          <input className={certificationStyle.input} id='nom'/>
        </div>
        <div className={certificationStyle.information}>
          Prénom :
          <br/> 
          <input className={certificationStyle.input} id='prenom'/>
        </div>
        <div className={certificationStyle.information}>
          Adresse Mail : 
          <br/>
          <input className={certificationStyle.input} id='mail'/>
        </div>
      </div>

      <div className={Component.style().button} style={{marginTop:'30px', marginBottom:'20px'}} onClick={send} id='bouton'>
        Demander votre certification
      </div>
      <br/><div className={certificationStyle.message} id='message'/>

      
    </div>
    </div>
    </>)
}

export default Element


/*
      
      
      <div className={certificationStyle.main} id='certification'>
        <div className={certificationStyle.column}>
          Le comité d'experts représenté par servicealapersonne.fr
            a le plaisir de vous attribuer le présent certificat, suite au suivi de la formation dédiée aux Services à la Personne.
        </div>
        <div className={certificationStyle.column}>
          Certificat Services à la Personne
        </div>
        <div className={certificationStyle.column}>
          Fait à Paris, en date du 
        </div>
        <div className={certificationStyle.column}>
          Le ou la Titulaire
        </div>
        <div className={certificationStyle.column}>
          Le comité d'experts servicealapersonne.fr
        </div>

      </div>
*/