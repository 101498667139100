import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'


import intro from './bakery2.jpg'
import chess from './group.jpg'
import conclusion from './hand.jpg'

function Element(props){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Services à la personne : Définir son offre commerciale</title>
      <meta name="description" content="Retrouver notre guide complet dédié aux Services à la Personne. Comment définir ses prix et services de ses prestations ?"/>
      </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Définir son offre Commerciale
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 2 mars 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'900px', marginTop:'30px', marginBottom:'-30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Dans un milieu concurrentiel proposer une offre adaptée à ses prospects est indispensable pour connaitre une croissance forte sur la durée.
        <br/>
        <br/>Offrir les tarifs les plus avantageux peut être tentant, mais sans innovation majeure permettant de réduire aussi les coûts, au long terme une guerre des prix finit soit par épuiser nos ressources (un auto-entrepreneur qui ne dispose plus de créneau pour des missions plus rentables), soit nous empêche d'investir pour atteindre le niveau supérieur.
        <br/>
        <br/>Nous allons voir qu'il existe de nombreuses autres possibilités, permettant à des offres très variées de co-exister un même marché, augmentant le bénéfice de ses acteurs ainsi que la satisfaction de ses clients.
        <br/>
        <br/>
        <span style={{color:ComponentArticle.color.column1Dark, fontWeight:'bold'}}>Sommaire :</span>
          <br/><span style={{color:'white'}}> .......... </span><a href='#prix'>1. Définir sa grille tarifaire</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#qualite'>2. Définir la qualité et périmètre de ses services</a>
          <br/><span style={{color:'white'}}> .......... </span><a href='#fidelisation'>3. Fidéliser ses clients</a>
        </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='prix'>
        1. Définir sa grille tarifaire
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La première étape est d'analyser votre marché : Les prix sont bien sûr très différents en fonction de l'activité, mais aussi de la zone géographique.
        Effectivement définir son offre sans consulter l'état actuel du marché n'aurait que peu de sens. 
        <br/>
        <br/>Des prix trop élevés limiteraient votre croissance, trop bas ils réduiraient votre marge et potentiellement mettraient en danger la survie de votre structure.
        <br/>
        <br/>On gardera évidemment en tête que le prix n'est pas le seul critère, et qu'il faut analyser les offres comparables entre elles (un professeur diplomé ne doit pas comparer son offre avec des profils plus juniors ou non déclarés).
        <br/>
        <br/>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Site Baromètre</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Une première analyse des prix pratiqués par son marché est d'analyser le Baromètre en ligne des prix du SAP.
        <br/>Celui-ci peut être ventilé par type d'activité, par zone géographique, ...
        <br/>
        <br/>Il s'agit d'un excellent moyen pour analyser les tendances de son marché ou étudier un nouveau marché avant de se lancer.
      </div>
      <a className={Component.style().button} onClick={props.changePopup} style={{margin:'20px 0px 20px 0px'}}>
        Baromètre des prix SAP
      </a>

      <div className={classes.text}>
      <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Analyse des concurrents directs</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Une seconde démarche est l'étude de ses concurrents les plus proches.
        <br/>Certains affichent directement leurs prix sur l'<a href='/annuaire'>annuaire du Service à la Personne</a>, ou sur leur propre site web.
        <br/>
        <br/>Si vous ne parvenez pas à obtenir leur grille tarifaire avec ces méthodes, il est toujours bon d'en discuter autour de soi, à des anciens clients, à ses partenaires commerciaux, ceux-ci sont toujours d'excellentes sources d'informations.
        <br/>
        <br/>
      </div>

      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Frais de déplacement et frais de gestion</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Une question fréquente est, dois-je facturer mes clients pour les grandes distances parcourues ?
        <br/>
        <br/>Cela dépend d'un grand nombre de paramètres, mais vous devez veiller à ce que ce temps et frais supplémentaires n'impactent pas trop fortement votre marge.
        <br/>Afin d'estimer ces frais, nous pouvons nous reposer sur le barèmes kilométrique qui estime à environ 64c€ le coût du km (consultable <a href='https://www.service-public.fr/particuliers/actualites/A14686' target='_blank' rel='nofollow'>ici</a>, il dépend aussi de la puissance du véhicule).
        <br/>
        <br/>Pour les frais de gestion, certains organismes appliquent une charge mensuelle en complément des prestations réalisées.
        <br/>Augmentant ainsi l'importance de l'analyse de votre marché afin d'établir votre stratégie. 
      </div>



      <img className={classes.image} src={chess} style={{width:'80vw', maxWidth:'700px', marginTop:'40px', marginBottom:'-15px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='qualite'>
        2. Définir la qualité et périmètre de ses services
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Maintenant que nous avons pu étudier les prix appliqués sur votre marché, il est important de construire son argumentaire : Pourquoi vos clients doivent absolument faire appel à vos services ?
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Références et exemples</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Si vous avez déjà réalisé des prestations, n'hésitez pas à les mettre en avant : La photo d'un jardin que vous avez réalisé, l'évolution physique d'un particulier (coach sportif), l'amélioration des notes scolaires d'un de vos anciens élèves, tel client satisfait qui a fait appel à vos services pour l'entretien de sa maison, ...
        <br/>
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Certifications et diplômes</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Game changer dans la plupart des secteurs : Un professeur assermenté, un aide à domicile certifié, sont autant d'arguments qui vous permettront de proposer des tarifs jusqu'à 2x voire 3 fois celui appliqué par la concurence.
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Services complémentaires</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Proposer plusieurs type de services peut apporter des synergies non négligeables à votre activité, quite à se rapprocher de vos homologues proposant d'autres types de SAP, afin de réaliser du cross selling (j'accompagne des personnes agées qui ont des besoins d'assistance informatique, je peux me rapprocher d'un professionnel du secteur, qui m'apportera des clients à son tour).
        <br/>
        
        <br/>N'hésitez pas à innover sur vos éléments différenciants, mais garder en tête que vos clients seront toujours votre meilleure source d'inspiration.
      </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='fidelisation'>
        3. Fidéliser ses clients
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Gagner des clients est primordial, mais cela est peu efficace sans fidélisation !
        <br/>Il est pourtant beaucoup moins couteux de conserver un actuel client que d'acquérir de nouveaux prospects.
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>L'importance des habitudes</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Un moyen simple de fidéliser un client est de créer une habitude : En fixant un horaire fixe chaque semaine, le rendez-vous suivant sera implicite.
        <br/>Pour de nouveaux clients, il est toujours important de fixer le rendez-vous suivant avant la fin de la prestation : il est facile de repousser la prise de rendez-vous indéfiniment.
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Offre spéciale</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Pour lancer une activité ou une campagne marketing, une offre temporaire est souvent l'élément qui facilite le passage à l'action. 
        <br/>Il peut s'agir d'une réduction mais aussi d'un bien ou service complémentaire (première heure gratuite, accompagnement, ...).
        <br/> 
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Pack ou abonnement</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Nous pouvons voir dans certains secteurs la vente de prestations uniquement par paquet ou abonnement.
        <br/>Comme les systèmes par coupons utilisés par les structures de soutien scolaire, limitant notamment le passage des prestations au non déclaré ;
        <br/>Ou le système d'abonnement, lui plutôt préféré par les coachs sportifs, engageant pour le particulier.
        <br/> 
      </div>

      
      <img className={classes.image} src={conclusion} style={{width:'80vw', maxWidth:'700px', marginTop:'40px', marginBottom:'-15px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        A travers l'analyse des prix, de la qualité des prestations, et des services complémentaires opérés sur le marché,
        la définition de votre stratégie tarifaire s'en trouvera d'autant plus adaptée à la demande existante.
        <br/>
        <br/>Une guerre des prix est rarement une stratégie rentable au long terme, à l'exception d'innovations majeures vous permettant de réduire vos coûts (informatisation ou outil permettant un gain de production considérable).
        <br/>
        <br/>Maintenant que nous avons défini notre inébranlable stratégie, il est temps de trouver nos premiers clients, sujet de notre <a href='/articleTrouverClient'>prochain article</a> !
      </div>
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
    </div>
    </div>
    </>)
}

export default Element