import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'
import Activity from './activity'


import intro from './city.jpg'
import imageSAP from './sap.png'
import imageGlobal from './puzzle.png'
import imageOld from './old.png'



function Element(){
  const classes = ComponentArticle.useStyles()
  
    return(<>
    <Helmet>
      <title>Guide SAP - Les Services à la Personne</title>
      <meta name="description" content="Guide complet Services à la Personne - Article dédié aux Services à la personne : Les 50% de crédit d'impôt, la condition d'exclusivité, ..."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Les Services à la Personne
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 15 février 2024
      </div>
      <img className={classes.image} src={intro} style={{width:'80%',borderRadius:'20px', marginTop:'20px', marginBottom:'0px'}}/>
      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Sommaire
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span><a href='#activite'>Les 26 activités eligibles</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#cae'>Condition d'Activité Exlusive</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#mode'>Modes Mandataire, Prestataire et Mise à disposition</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#offreGenerale'>Offre Générale de Service</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#personneFragile'>Agrément personnes fragiles</a>
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les Services à la Personne (SAP) aussi appellés Aide à domicile, regroupent l'ensemble des services rendus aux particuliers, le plus souvent à leur domicile.
        <br/>
        <br/>Faire appel à une structure de SAP, permet aux particuliers de bénéficier d'un crédit d'impôt <span className={classes.textImportant}>sur 50% du montant des prestations perçues.</span>
        <br/>
        <br/>C'est aujourd'hui 26 activités éligibles et plus de 62 000 organismes qui proposent leurs services !
        <br/>Toutes les formes juridiques (micro-entreprise, société individuelle, SARL, SAS, association, coopératives, ...) peuvent devenir Service à la Personne (Elles sont appellées indiféremment Organismes de Service à la Personne).
        <br/>
      </div>

      <div className={classes.tableau}>
        <div className={classes.tableauColumn} style={{color:'orange'}}> 
          <span style={{fontSize:'2em', fontWeight:'bold',}}> 26 </span>
            activités éligibles
        </div>
        <div className={classes.tableauColumn} style={{color:ComponentArticle.color.column1Dark}}> 
          <span style={{fontSize:'2em', fontWeight:'bold',}}> 62 000 </span>
          organismes
        </div>
        <div className={classes.tableauColumn} style={{color:'orange'}}> 
          <span style={{fontSize:'2em', fontWeight:'bold',}}> 50 % </span>
            de crédit d'impôt
        </div>
      </div>

      <div className={classes.text}>
        <br/>Par les nombreuses aides dont bénéficie le secteur (crédit d'impot, APA, PCH, CAF), les Services à la Personne ont un cadre bien spécifique.
        <br/>
        <br/>C'est pourquoi nous avons tenté de rassembler l'ensemble des informations utiles en un unique lieu : 
        <br/>Que vous réfléchissez à vous lancer, ou tout au long de votre développement, vous pourrez le consulter comme guide. Vous pourrez y trouver l'ensemble des informations sur le SAP, mais aussi quelle forme juridique choisir, comment se rémunérer, les outils de gestions éprouvés et bien plus encore. 
        <br/>
        <br/>
      </div>

      <img className={classes.image} src={imageSAP} style={{width:'40vw', maxWidth:'250px', }}/>
      <div className={classes.imageDescription}>Le logo des organismes de SAP</div>
      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='activite'>
          Les 26 activités éligibles
      </div>
      <div className={classes.text}>
          Sélectionner une activité afin d'obtenir de plus amples informations.
          <br/>Les descriptions ci-dessous sont issues de la <a href='https://www.economie.gouv.fr/files/files/directions_services/bulletin-officiel/2019_pdf/2019-05/boe_20190005_0000_0008.pdf' rel='nofollow' target='_blank'>circulaire officielle du 11 avril 2019</a>, 
          les activités avec un astérisque* nécessitent un agrément supplémentaire.
      </div>
      <Activity/>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='cae'>
        Condition d'activité exclusive
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Comme nous avons pu le voir, les organismes de SAP profitent d'un avantage fiscal conséquent pour leurs clients.
        <br/>
        <br/>En contrepartie, ils doivent respecter la Condition d'Activité Exclusive (appelée parfois CAE), et réaliser exclusivement des prestations incluses dans 26 activités du Service à la Personne.
        <br/>En d'autres termes, il n'est plus possible de réaliser des prestations pour des professionnels, vendre des marchandises, ou encore des prestations pour les particuliers non incluses dans les 26 activités présentées précédemment.
        <br/>
        <br/>Cependant, pour certaines typologies de structure  (association, organisme gestionnaire d'un établissement de santé ou d'accueil d'enfants, gérants de résidences-services, ...), 
        il est possible de déroger à cette condition d'activité exclusive, si une comptabilité séparée est mise en place.
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='mode'>
        Modes Mandataire, Prestataire et Mise à disposition 
      </div>
      <img src={imageGlobal} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px',}}/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les modes d'interventions définissent le contrat qui lie les trois parties : Particulier / Intervenant / Organisme SAP.
        <br/>
        <br/> Si nous souhaitions citer leurs principales différences :
        <br/>
        <br/><strong>Mandataire :</strong> 
        <br/>C'est ici le particulier qui emploie directement l'intervenant. 
        <br/>La structure s'occupe de sélectionner l'intervenant et réalise les déclarations administratives et sociales.
        <br/>
        <br/><strong>Prestataire :</strong> 
        <br/>L'organisme emploie l'intervenant. 
        <br/>La structure facture le particulier, et s'occupe du suivi des prestations réalisées.
        <br/>
        <br/><strong>Mise à disposition :</strong> 
        <br/>L'organisme emploie l'intervenant. 
        <br/>La structure facture le particulier.
        <br/>Le particulier par délégation, exerce plus de responsabilité sur le travail effectué (définition des tâches, horaires, ...) 
         
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='offreGenerale'>
        Condition "Offre globale de services"
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Certaines activités demandent à être intégrées dans une 'offre globale de services' afin de permettre aux particuliers de percevoir l'avantage fiscal.
        <br/>C'est à dire que celles-ci doivent être accessoires par rapport à une autre activité SAP.
        <br/>
        <br/>Par exemple, une prestation de transport nécessite d'être intégrée à une offre globale.
        <br/>Le particulier pourra bénéficier du crédit d'impôt sur cette dernière, uniquement si il a aussi consommé un autre service non accessoire (par exemple du ménage).
        <br/>
        <br/>L'ensemble des activités devant faire partie d'une offre globale de services sont :
          <ul>
            <li>Accompagnement d'enfants dans leurs déplacements ;</li>
            <li>Conduite du véhicule personnel ou accompagnement dans leurs déplacements, de personnes fragiles ou temporairement dépendantes ;</li>
            <li>Livraison de repas, de linge et de courses.</li>
          </ul>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='personneFragile'>
        Agrément Personnes fragiles
      </div>
      <img src={imageOld} style={{width:'70vw', maxWidth:'500px', marginBottom:'15px'}}/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Les activités en contact avec des enfants de moins de 3 ans ou mineurs handicapés, nécessitent un agrément supplémentaire pour les mandataires et prestataires :
        <ul>
          <li>Garde d'enfant de moins de 3 ans et de moins de 18ans handicapés à domicile ;</li>
          <li>Accompagnement dans leurs déplacements d'enfant de moins de 3 ans et de moins de 18ans handicapés à domicile ;</li>
        </ul>
        Les activités suivantes, en contact avec des personnes fragiles, nécessitent un agrément supplémentaire pour les mandataires, et une autorisation pour les prestataires : 
        <ul>
          <li>Assistance aux personnes âgées et aux personnes ayant des difficultés de mobilité ;</li>
          <li>Conduite du véhicule des personnes ayant des difficultés de mobilité ;</li>
          <li>Accompagnement des personnes en dehors de leur domicile ;</li>
        </ul>
        La demande d'agrément doit être réalisée sur le site <a href='https://nova.entreprises.gouv.fr/accueil/nc' rel='nofollow' target='_blank'>Nova</a>, et l'organisme doit suivre un cahier des charges précis, 
        explicité au sein de l'<a href='https://nova.entreprises.gouv.fr/nova/accueil/nc/ressource/526180/get-file' rel='nofollow' target='_blank'>arrêté du 24 novembre 2023</a> fixant le cahier des charges pour obtenir l'agrément.
        <br/>Pour les demandes d'agrément, vous pourrez vous rapprocher de votre antenne territorial DREETS (anciennement appelée DIRECCTE).
        <br/>
        <br/>Les autres typologies de services (ménage, jardinage, cours particuliers, ...) nécessitent uniquement une déclaration sur le site Nova, qui sera validée par votre antenne DREETS.
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
        <div className={classes.text}><span style={{color:'white'}}> .......... </span>
        Nous avons ainsi pu tracer le contour du Service à la personne,
        permettant ainsi à nos clients de bénéficier des 50% de crédit d'impôt.
        <br/>
        <br/>Nous allons maintenant étudier comment devenir un organisme de SAP. Nous répondrons également à l'ensemble des interrogations qu'un professionnel pourrait rencontrer :
        quelle forme juridique choisir pour votre structure, comment développer efficacement votre clientèle ou encore quels logiciels choisir pour gérer votre activité.
        <br/>
        <br/>
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>

    </div>
    </div>



    </>)
}

export default Element