import { useState, useEffect, useLayoutEffect } from "react"
import {clsx} from 'clsx'
import { makeStyles } from "@mui/styles"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import mongoWrite from '../../Component/mongoWrite'

import n26 from './n26.png'
import finom from './finom.png'
import blank from './blank.png'
import qonto from './qonto.png'
import shine from './shine.png'
import propulse from './propulse2.png'
import hb from './hb.png'
import sg from './sg.png'
import ca from './ca3.png'
import bp from './bp.png'

const useStyles = makeStyles({
    tableau:{
        display:'flex',
        flexDirection:'column',
        //border:'2px solid',
    },
    line:{
        //width:'100%',
        minHeight:'80px',
        padding:'0vw 1vw 0vw 1vw',

        display:'flex',
        flexDirection:'row',
        alignItems:'center',

        //color:'pink',
    },
    lineTitle:{
        background:ComponentArticle.color.column1Dark,
        borderRadius:'10px 10px 0px 0px',
        color:'white',
        fontWeight:'bold',
    },
    element:{
        width:'10vw', //pour que le nombre colonne * width >= 100%
        //minWidth:'60px',
        maxWidth:'140px', //pour que le nombre colonne * width >= 100%
        height:'7vw', //pour que le nombre colonne * width >= 100%
        maxHeight:'100px', //pour que le nombre colonne * width >= 100%

        //textAlign:'center',
        //padding:'10px',
        
        display:'flex',
        justifyContent:'center',
        alignItems:'center',

        //border:'2px solid',
        "@media (max-width: 700px)": {width:'11.5vw',},
        "@media (max-width: 600px)": {width:'13vw',},
        "@media (max-width: 500px)": {width:'15vw',},
        "@media (max-width: 400px)": {width:'17vw',},
    },
    elementText:{
        width:'100%',
        //fontSize:'1vw', 
        textAlign:'center',
        //border:'2px solid pink',
        "@media (max-width: 1050px)": {
            fontSize: "0.8em",
        },
    },
    button:{
        //padding:'7px 15px 10px 15px',
        padding:'0.4vw 0.4vw 0.4vw 0.4vw',
        //width:'80px',
        
        background:Component.color.main1,
        cursor:'pointer',
        borderRadius:'1vw',
        boxShadow:'2px 2px 8px 3px rgba(200,200,200)',
          
        color:'white',
        fontWeight:'bold',
        textDecoration:'none',
        transition:'1s ease-in-out',
        "&:hover": {
          boxShadow:'6px 6px 0px '+Component.color.main2,
          filter:'brightness(1.1)',
          transition:'0.3s ease-in-out',
        },
      },
})

function Element(){
    //const classes = ComponentArticle.useStyles()
    const classes = useStyles()

    let listLine=[
      {nom:'', description:'Description', prix:'Prix HT', cartePhysique:'Carte physique', carteVirtuelle:'Carte virtuelle', depot:'Dépôt chèques et espèces', tpe:'Terminal de paiement', support:'Support', decouvrir:'Offres'},
      //{id:'n26Standard', nom:'N26<br/>(AE seulement)', image:n26, description:'Auto-entreprise uniquement. Iban Allemand', prix:'0€', prelevement:'Illimités', tpe:'95€+9,9€/mois', cartePhysique:'10€', carteVirtuelle:'1', depot:'Non', support:'Non', decouvrir:'Découvrir', link:'https://n26.com/fr-fr/compte-bancaire-auto-entrepreneur-gratuit'},
      //{id:'n26Smart', nom:'N26<br/>(AE seulement)', image:n26, description:'Auto-entreprise uniquement. Iban Allemand', prix:'4,90€', prelevement:'Illimités', tpe:'95€+9,9€/mois', cartePhysique:'1', carteVirtuelle:'1', depot:'Non', support:'Oui', decouvrir:'Découvrir', link:'https://n26.com/fr-fr/compte-bancaire-auto-entrepreneur'},
      {id:'finom', nom:'Finom<br/>', image:finom, description:'Banque européenne en ligne avec 85 000 clients', prix:'5€', prelevement:'50', prelevementSup:'0,2€', tpe:'Non', cartePhysique:'1', carteVirtuelle:'1', depot:'Non', support:'Oui', decouvrir:'3mois offerts', link:'https://app.finom.co/en/signup?utm_source=partner&utm_medium=cpp&utm_campaign=AIS-banking-.co-France&fnm_product=business&fnm_partner=AIS'},
      {id:'blank', nom:'Blank<br/>', image:blank, description:'La banque des indépendants', prix:'6€', prelevement:'30', tpe:'Non', cartePhysique:'1', carteVirtuelle:'0', depot:'0,9€/chq', support:'Oui', decouvrir:'1mois offert', link:'https://lb.affilae.com/r/?p=5faac37a7e67fa729bd876fb&af=388&lp=https%3A%2F%2Flanding.blank.app%2Fouvrir-un-compte-professionnel-blank%3Futm_source%3Daffilae%26utm_medium%3Daffiliation%26utm_campaign%3DAvance%2520Imm%25C3%25A9diate%2520Services'},
      {id:'shine', nom:'Shine<br/>(Société Générale)', image:shine, description:'Rachetée par la Société Générale en 2020', prix:'7,90€', prelevement:'30', tpe:'32€', cartePhysique:'1', carteVirtuelle:'1', depot:'Non', support:'Oui', decouvrir:'Découvrir', link:'https://www.shine.fr/?utm_source=affiliation&utm_medium=affilae&utm_campaign=Avance%20Imm%C3%A9diate%20Services&ae=756'},
      {id:'propulse', nom:'Propulse<br/>(Crédit Agricole)', image:propulse, description:'Filiale de Crédit Agricole', prix:'8€', prelevement:'Illimités', tpe:'Non', cartePhysique:'1', carteVirtuelle:'0', depot:'Non', support:'Mail', decouvrir:'Découvrir', link:'https://lb.affilae.com/r/?p=646330bc10a059b66d31103c&af=191&lp=https%3A%2F%2Fpropulsebyca.fr%2F'},
      //{id:'helloBank', nom:'HelloBank<br/>(BNP Paribas)', image:hb, description:'AE et EI uniquement. Filiale de BNP Paribas', prix:'10,90€', prelevement:'Illimités', tpe:'39€', cartePhysique:'1', carteVirtuelle:'0', depot:'Oui', support:'Oui', decouvrir:'Découvrir', link:'https://www.hellobankpro.fr/'},
      //{id:'qonto', nom:'Qonto', image:qonto, description:'Banque française créée en 2016', prix:'11€', prelevement:'30', tpe:'39€', cartePhysique:'1', carteVirtuelle:'2€/mois', depot:'Non', support:'Oui', decouvrir:'Découvrir', link:'https://qonto.com/fr'},
      {id:'banquePopulaire', nom:'Banque Populaire', image:bp, description:'Membre du groupe BPCE avec Crédit d\'Epargne', prix:'9,90€', prelevement:'Illimités', tpe:'sur devis', cartePhysique:'1', carteVirtuelle:'0', depot:'Oui', support:'Conseiller<br/> dédié', decouvrir:'Découvrir', link:'https://www.credit-agricole.fr/professionnel.html'},
      {id:'creditAgricole', nom:'Crédit Agricole', image:ca, description:'Banque mutualiste créée en 1894', prix:'25€', prelevement:'Illimités', tpe:'95€+9,9€/mois', cartePhysique:'1', carteVirtuelle:'1', depot:'Oui', support:'Conseiller<br/> dédié', decouvrir:'Découvrir', link:'https://www.credit-agricole.fr/professionnel.html'},
      {id:'societeGenerale', nom:'Société Générale<br/>(Jazz pro)', image:sg, description:'3ème banque française, créée en 1864', prix:'38€', prelevement:'Illimités', tpe:'95€+9,9€/mois', cartePhysique:'1', carteVirtuelle:'0', depot:'Oui', support:'Conseiller<br/> dédié', decouvrir:'Découvrir', link:'https://professionnels.sg.fr/'},
    ]

    useLayoutEffect(() => {
        //RAZ
        let tableau = document.getElementById('tableau')
        tableau.innerHTML = ''
        //on cache certaine colonne si taille écran trop petite
        if(window.innerWidth<700){ for(let i in listLine){delete listLine[i].description}}
        if(window.innerWidth<600){ for(let i in listLine){delete listLine[i].tpe}}
        if(window.innerWidth<500){ for(let i in listLine){delete listLine[i].support}}
        if(window.innerWidth<400){ for(let i in listLine){delete listLine[i].carteVirtuelle}}
        //tab
        for(let [l,line] of Object.entries(listLine)){
            //on créer une ligne
            let elementLine = document.createElement('div')
            if(l==0){elementLine.className=clsx(classes.line, classes.lineTitle)}
            else{elementLine.className=classes.line}
            if(l%2==0 && l>0){elementLine.style.background=ComponentArticle.color.column1Light}
            if(l==(listLine.length-1)){elementLine.style.borderRadius='0px 0px 10px 10px'}
            //on créer chaque case
            for(let column of Object.keys(listLine[0])){
                let element = document.createElement('div')
                element.className=classes.element
                //on créer le contenu de la case
                let elementIn = document.createElement('div')
                elementIn.className=classes.elementText
                elementIn.innerHTML=line[column]
                //case spéciale
                if(column=='nom' && !!line.image){
                    elementIn = document.createElement('img')
                    elementIn.className=classes.elementText//Image
                    elementIn.style.width='7vw'
                    elementIn.style.minWidth='60px'
                    //elementIn.style.maxWidth='80px'
                    elementIn.src=line.image  
                }
                if(column=='decouvrir' && l>0){
                    elementIn = document.createElement('a')
                    //elementIn.className=classes.button
                    elementIn.className=clsx(classes.button, classes.elementText)
                    elementIn.innerHTML=line[column]
                    elementIn.href=line.link
                    elementIn.target='_blank'
                    elementIn.rel='nofollow'
                    elementIn.onclick=()=>{mongoWrite({request:'write', collection:'banque', body:{text:line.id}})}
                }
                element.appendChild(elementIn)
                elementLine.appendChild(element)
            }
            tableau.appendChild(elementLine)
        }
        
    })
  
    return(<>
        <div className={classes.tableau} id ='tableau'>
        </div>

    </>)
}

export default Element